<template>
  <div class="user-select">
    <BaseElSelect
      v-model="syncModel"
      value-key="id"
      multiple
      collapse-tags
      placeholder="請選擇人員"
      no-data-text="暫無數據"
      @change="$emit('change')"
    >
      <BaseElSelectOption
        v-for="item in shopList"
        :key="item.id"
        :label="`${item.name} (${item.id.slice(-5)})`"
        :value="item"
      />
    </BaseElSelect>
  </div>
</template>

<script>
import { GetUser, GetUsersCount } from '@/api/user'
export default {
  name: 'UserSelect',
  props: ['model', 'role'],

  data: () => ({
    shopList: [],
  }),

  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getAllUser()
  },

  methods: {
    async getAllUser () {
      try {
        const role = this.role
        const count = await GetUsersCount({ role })
        let start = 0
        const limit = 10

        this.shopList = await GetUser({ start, limit, role })

        while (this.shopList.length < count) {
          start += 10
          this.shopList = this.shopList.concat(await GetUser({ start, limit, role }))
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
