<template>
  <div class="service-unit-setting">
    <PageTitle
      title="服務人員設定"
      cyBtn1="new-unit-btn"
      btn="新增"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_name"
        clearable
        placeholder="輸入服務人員姓名"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <p>服務人員總數：{{ totalUnitCount }} / 合約人員總數：{{ contractUnitCount }}</p>
      <ProgressBar :percentage="unitCountPercentage" />
      <!-- <el-progress :text-inside="true" :stroke-width="20" :percentage="unitCountPercentage" :color="colorList"></el-progress> -->
    </section>

    <section>
      <BaseTable v-loading="loading" :data="serviceUnitList" empty-text="暫無數據" testName="預約服務人員">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="圖片" align="center" width="150">
          <template slot-scope="scope">
            <img style="margin: auto;" width="90" :src="imgSource(scope.row.Image)" alt="">
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="姓名" align="center" />
        <BaseElTableColumn prop="color" label="顏色" align="center">
          <template slot-scope="scope">
            <div
              v-if="scope.row.color"
              :style="
                `
            background: ${scope.row.color};
            width: 50%;
            height: 20px;
            margin: auto;
            `
              "
            />
          </template>
        </BaseElTableColumn>
        <!-- <BaseElTableColumn prop="description" label="敘述" align="center" show-overflow-tooltip="" /> -->
        <BaseElTableColumn prop="bookingMax" label="同時段服務數量" width="150" align="center" />
        <BaseElTableColumn label="公開顯示" width="150" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? '是' : '否' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="Google日曆" width="150" align="center">
          <template slot-scope="scope">
            <BaseElButton type="text" class="text-btn" @click="openBindDialog(scope.row)">綁定</BaseElButton>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :testName="scope.row?.name"
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row, findServiceUnit()"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceUnitCount"
        @pageChange="refresh"
      />
    </section>

    <image-cropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />

    <!-- 新增服務人員 Dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
        <BaseElFormItem label="圖片">
          <UploadButton
            cyUploadBtn="service-unit-img-upload"
            cyUploadedImg="service-unit-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem label="姓名" prop="name">
          <BaseElInput
            v-model="formData.name"
            :maxlength="55"
            show-word-limit
            testName="formData_name"
            placeholder="請輸入姓名"
          />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput
            v-model="formData.order"
            testName="formData_order"
            placeholder="請輸入排序"
          />
        </BaseElFormItem>
        <BaseElFormItem label="顏色" prop="color">
          <BaseElSelect
            v-model="formData.color"
            testName="formData_color"
            placeholder="請選擇服務人員顏色"
            no-data-text="無數據"
            class="color"
          >
            <BaseElSelectOption
              v-for="item in colorList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
              <div
                :style="
                  `margin:5px 0; background: ${item.code}; color: white; width:100%; height: 28px; border-radius:4px;`
                "
              />
            </BaseElSelectOption>
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem label="公開顯示" prop="public">
          <BaseElSwitch
            v-model="formData.isPublic"
            testName="formData_isPublic"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>
        <BaseElFormItem label="同時段服務數量" prop="bookingMax">
          <BaseElInput
            v-model="formData.bookingMax"
            testName="formData_bookingMax"
          />
        </BaseElFormItem>
        <BaseElFormItem label="服務項目" prop="appointmentServices">
          <UnitServicesSelect
            testName="formData_services"
            :model.sync="formData.services"
            multiple
            :showAll="true"
            :option-filter="bindingServicesFilter"
          />
          <!-- <ServiceSearch class="w-full" :model.sync="formData.services" multiple/> -->
        </BaseElFormItem>
        <BaseElFormItem label="綁定服務人員 (限定店家服務人員)" prop="appointmentServices">
          <!-- <UserSearch class="w-full" :model.sync="formData.users" role="shopProvider" multiple/> -->
          <BaseElSelect
            v-model="formData.users"
            testName="formData_users"
            value-key="id"
            multiple
            collapse-tags
            placeholder="請選擇人員"
            no-data-text="暫無數據"
          >
            <BaseElSelectOption
              v-for="item in allowBindUserList"
              :key="item.id"
              :label="`${item.name} (${item.id.slice(-5)})`"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton testName="edit-dialog_cancel-btn" plain @click="showDialog = false, resetForm()">取消</BaseElButton>
        <BaseElButton testName="edit-dialog_confirm-btn" type="primary" @click="dialogConfirm">儲存</BaseElButton>
      </div>
    </el-dialog>

    <el-dialog v-if="selectRow" :close-on-click-modal="false" :title="`Google日曆綁定 - ${selectRow.name}`" :visible.sync="bindDialog" @close="resetForm()">
      <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="Gmail帳號" prop="gmail">
          <div class="flex-between">
            <BaseElInput v-model="formData.gmail" v-loading="gLoading" placeholder="請輸入" :disabled="gLoading" />
            <BaseElButton type="text" class="text-btn" @click="bindCalendar">連動</BaseElButton>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <div class="dashed-line">
        <svg viewBox="0 0 500 10">
          <line
            x1="0"
            y1="5"
            x2="500"
            y2="5"
            stroke="black"
            stroke-dasharray="7 5"
          />
        </svg>
      </div>

      <div class="flex-col mb-2">
        <span style="color: #2D271B; font-weight: 500;">已綁定帳號</span>
        <span style="color: #2BB8C1; font-weight: 400;">提醒：綁定帳號後記得前往gmail收信，並加入日曆！</span>
      </div>

      <BaseElForm label-position="top">
        <BaseElFormItem v-for="account in gCalendarList" :key="account.id" v-loading="gLoading" label="Gmail帳號">
          <div class="flex-between">
            <span style="font-weight: 400;">{{ account.scope.value }}</span>
            <BaseElButton
              type="text"
              class="cancel-text-btn"
              testName="remove-google-calendar"
              @click="deleteGoogleCalendar(account.scope.value)"
            >
              移除
            </BaseElButton>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="bindDialog = false, resetForm()">返回</BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      data-testid="service-setting_delete-dialog"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceUnit(), deleteDialog=false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProgressBar from '@/components/Progress/ProgressBar'
import UserSelect from '@/components/Select/UserSelect.vue'
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { noEmptyRules, isDigitRules, rangeRules, emailRules } from '@/validation'
import {
  CreateServiceUnit,
  GetServiceUnit,
  GetServiceUnitCount,
  DeleteServiceUnit,
  UpdateServiceUnit,
  FindServiceUnit,
  GetGoogleCalendar,
  CreateGoogleCalendar,
  DeleteGoogleCalendar,
  GetAllowBindUserList,
} from '@/api/serviceUnit'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'
import { shiftColors } from '@/config/reservation'
import { find, get } from 'lodash'
// // import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceUnitSetting',
  components: { ProgressBar, UploadButton, ImageCropper, DeleteDialog, EmptyBlock, UnitServicesSelect },
  setup () {
    const bindingServicesFilter = (service) => {
      if (!service.enableAppointmentUnit) return false
      return true
    }
    return { bindingServicesFilter }
  },
  data: () => ({
    nameSearch: '',
    loading: false,
    gLoading: false,
    showDialog: false,
    deleteDialog: false,
    uploadDialog: false,
    bindDialog: false,
    dialogType: '',

    selectRow: null,
    selectUnit: null,

    avatarChanged: false,
    // imgSize: 90,
    imgSize: 90,

    colorList: shiftColors,

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    totalUnitCount: 0,
    allowBindUserList: [],
    serviceUnitList: [],
    serviceUnitCount: 0,

    gCalendarList: [],

    // Form
    formData: {
      gmail: '',
      img: null,
      avatar: null,
      name: '',
      description: '',
      isPublic: true,
      bookingMax: '',
      services: [],
      users: [],
      order: 100,
      color: null,
    },

    formRules: {
      gmail: emailRules(),
      name: noEmptyRules('請輸入服務人員名稱'),
      // description: noEmptyRules('請輸入服務人員敘述'),
      bookingMax: [isDigitRules(), noEmptyRules('請輸入服務數量'), rangeRules()],
      order: [isDigitRules(), noEmptyRules('請輸入排序')],
      color: [noEmptyRules()],
    },

  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
    ]),

    contractUnitCount () {
      return this.userPlanLimit.appointmentUnitLimit
    },

    unitCountPercentage () {
      if (this.userPlanLimit.appointmentUnitLimit === 0) {
        return 0
      }

      const count = Math.round(this.totalUnitCount * 100 / this.userPlanLimit.appointmentUnitLimit)
      return count > 100 ? 100 : count
    },

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    user () {
      return this.$store.state.user
    },

    userList () {
      return extractList('id', this.formData.users)
    },

    servicesList () {
      return extractList('id', this.formData.services)
    },

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增服務人員',
          update: '編輯服務人員',
        },
      )
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if(! val) type = 'danger'
      return type
    },
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },
    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.order = row.order
      this.formData.avatar = row.Image
      this.formData.description = row.description
      this.formData.isPublic = row.isPublic
      const color = find(this.colorList, { code: row.color })
      this.formData.color = get(color, 'name')
      this.formData.bookingMax = row.bookingMax.toString()
    },

    async getAllowBindUserList () {
      const [res, err] = await GetAllowBindUserList({
        shopId: this.shop,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.allowBindUserList = res
    },

    async refresh () {
      this.loading = true
      await Promise.allSettled([
        this.getAllowBindUserList(),
        this.getServiceUnit(),
        this.getServiceUnitCount(),
        this.getTotalUnitCount(),
      ])
      // await this.getServiceUnit()
      // await this.getServiceUnitCount()
      // await this.getTotalUnitCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createServiceUnit()
      if (type === 'update') this.updateServiceUnit()
      this.showDialog = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        avatar: null,
        name: '',
        description: '',
        isPublic: true,
        bookingMax: '',
        services: [],
        users: [],
        order: 100,
        color: null,
      }
    },

    async openBindDialog (row) {
      this.bindDialog = true
      this.selectRow = row
      await this.getGoogleCalendar()
    },

    async bindCalendar () {
      try {
        if (!await this.checkForm()) return
        this.gLoading = true
        await CreateGoogleCalendar({
          shopId: this.shop,
          id: this.selectRow.id,
          email: this.formData.gmail,
        })
        this.formData.gmail = ''
        await this.getGoogleCalendar()
        this.$message.success('連動成功')
        this.gLoading = false
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async getGoogleCalendar () {
      try {
        this.gLoading = true
        const res = await GetGoogleCalendar({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.gCalendarList = res
        this.gLoading = false
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async deleteGoogleCalendar (email) {
      try {
        await DeleteGoogleCalendar({
          shopId: this.shop,
          id: this.selectRow.id,
          email,
        })
        await this.getGoogleCalendar()
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    //= > 創建服務人員
    async createServiceUnit () {
      if (!this.shop) this.$message.error('請先選擇店家')
      try {
        await CreateServiceUnit({
          shopId: this.shop,
          name: this.formData.name,
          image: (!this.formData.avatar) ? undefined : this.formData.avatar.id,
          // description: this.formData.description,
          bookingMax: this.formData.bookingMax,
          isPublic: this.formData.isPublic,
          appointmentServices: this.servicesList,
          users: this.userList,
          order: this.formData.order,
          color: find(this.colorList, { name: this.formData.color }).code,
        })
        await this.refresh()
        this.$message.success('新增成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 刪除服務人員
    async deleteServiceUnit () {
      try {
        await DeleteServiceUnit({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 更新服務人員
    async updateServiceUnit () {
      const colorName = get(this.formData, 'color')
      const color = find(this.colorList, { name: colorName })
      try {
        await UpdateServiceUnit({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
          name: this.formData.name,
          image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
          // description: this.formData.description,
          bookingMax: this.formData.bookingMax,
          isPublic: this.formData.isPublic,
          appointmentServices: this.servicesList,
          users: this.userList,
          order: this.formData.order,
          color: get(color, 'code'),
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務人員
    async getServiceUnit () {
      this.loading = true
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetServiceUnit({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceUnitList = res
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務人員總數(無過濾)
    async getTotalUnitCount () {
      try {
        const res = await GetServiceUnitCount({
          shopId: this.shop,
        })
        this.totalUnitCount = res
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務人員總數
    async getServiceUnitCount () {
      try {
        const res = await GetServiceUnitCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceUnitCount = res
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得指定服務人員
    async findServiceUnit () {
      try {
        const res = await FindServiceUnit({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.selectUnit = res
        this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
        this.formData.users = res.Users
      } catch (error) {
        this.$message.warning(error)
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },

}
</script>

<style lang="scss" scoped>
.text-btn {
  @apply text-normal text-primary-100;
}
.cancel-text-btn {
  font-size: 16px;
}
line {
  stroke: #5E5E5E;
}
.dashed-line {
  height: 13px;
  stroke-width: 0.5;
  margin-bottom: 20px;
}
</style>
